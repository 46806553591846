<template>
  <div class="mb-horarios__lista-horarios">
    <div class="mb-horario__container-labels border-b border-gray-300 pb-8 sticky grid grid-cols-3 gap-2 dark:border-gray-700">
      <div>
        <span class="uppercase tracking-wider text-xs leading-none text-gray-600 dark:text-gray-400">Salida</span>
        <h2 class="leading-tight">{{ estacionSalida?.nom }}</h2>
      </div>
      <div>
        <span class="uppercase tracking-wider text-xs leading-none text-gray-600 dark:text-gray-400">Llegada</span>
        <h2 class="leading-tight">{{ estacionLlegada?.nom }}</h2>
      </div>
    </div>

    <div class="mb-horario__lista-horas">
      <div class="text-xl my-20 mb-horario-sinhoras" v-if="!listaHorarios.length">
        <em>No hay horarios disponibles a esta hora :(</em>
      </div>
      <div v-else-if="isLoadingHorarios" class="flex items-center justify-center pt-24">
        <Icon name="line-md:loading-twotone-loop" width="21" height="21" />
      </div>
      <HorarioSingle
        v-else
        v-for="horario in listaHorarios"
        :horario="horario"
        :estacion-salida="estacionSalida?.nom || 'Salida'"
        :estacion-llegada="estacionLlegada?.nom || 'Llegada'"
        :horarios-vuelta="horariosVuelta"
        :key="`${horario.id}|${horario.S}:${horario.T}`"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import capitalize from "lodash/capitalize";
import deburr from "lodash/deburr";

import type { Horario, IEstacion } from "@/interfaces";
import { formatDate } from "@/helpers/hora";
import { horaState } from "@/store/hora";

const props = defineProps({
  limite: Number,
  horarios: Array as PropType<Horario[]>,
  horariosVuelta: Map as PropType<Map<string, Horario>>,
  estacionSalida: Object as PropType<IEstacion | undefined>,
  estacionLlegada: Object as PropType<IEstacion | undefined>,
  fecha: [Date, String],
  isLoadingHorarios: Boolean,
});

const DAYS = {
  Lu: "L-V",
  Ma: "L-V",
  Mi: "L-V",
  Ju: "L-V",
  Vi: "L-V",
  Sa: "S",
  Do: "D", 
} as const;
type DAYS_KEYS = keyof typeof DAYS;

const horaBase = horaState();
const fecha = computed(() => horaBase.date);
const hora = computed(() =>
  new Date(fecha.value).toLocaleTimeString("es-CL", {
    hour12: false,
    minute: "2-digit",
    hour: "2-digit",
    timeZone: "America/Santiago",
  })
);

const dia = computed<DAYS_KEYS>(
  () =>
    deburr(
      capitalize(
        new Date(fecha.value)
          .toLocaleDateString("es-CL", {
            weekday: "short",
          })
          .slice(0, 2)
      )
    ) as DAYS_KEYS
);

const filtroHorarios = (
  horarios: Horario[],
  limite = 0,
  hr = "00:00",
  day: DAYS_KEYS = "Lu"
) => {
  if (!horarios || !hr) return [];
  let fstVigenteFiltered = 0;

  const filtered = horarios
    .filter((h) => {
      const arrayVigencia = h.V || "";
      return h.S > hr && arrayVigencia.includes(DAYS[day]);
    })
    .map((h) => {
      const primer = !fstVigenteFiltered ? { primero: true } : {};
      fstVigenteFiltered++;
      return Object.assign({}, h, { vigente: true }, primer);
    });

  let fstDentroHorario = 0;
  const dentroHorario = horarios.map((h) => {
    const arrayVigencia = h.V || "";
    const condicion = h.S > hr && arrayVigencia.includes(DAYS[day]);
    const primer = condicion && !fstDentroHorario ? { primero: true } : {};
    condicion && fstDentroHorario++;

    return condicion ? Object.assign({}, h, { vigente: true }, primer) : h;
  });

  return limite ? filtered.slice(0, limite) : dentroHorario;
};

const separarHora = (hora: string) => {
  if (!hora) return "00:00";
  if (!/^\d{1,2}:\d{2}/.test(hora)) return "00:00";

  const [h, m] = hora.split(":");
  const A = new Date().getFullYear();
  const M = new Date().getMonth();
  const D = new Date().getDate();

  const Fecha = new Date(A, M, D, parseInt(h, 10), parseInt(m, 10));
  const Tiempo = formatDate(Fecha);

  return Tiempo?.replace(
    /(.+) (\d+) (h|m)?/gi,
    (str: string, $1: string, $2: string, $3: string) => {
      const ext = $3 ? $3?.replace(/horas?/, "hr")?.replace(/minutos?/, "min") : "";
      const prefix = $1 ? $1?.replace("dentro de", "en")?.trim() : "";

      return `${prefix} ${$2} ${ext}`;
    }
  );
};

const listaHorarios = computed(() => {
  const ordenados = JSON.parse(JSON.stringify(props.horarios || []))
    .filter((h: Horario) => {
      return /^\d{1,2}:\d{2}/.test(h.S);
    })
    .sort((hA: Horario, hB: Horario) => {
      const a = new Date(`2020-01-31T${hA.S}`).getTime();
      const b = new Date(`2020-01-31T${hB.S}`).getTime();
      if (Number.isNaN(a) || Number.isNaN(b)) return 0;

      return a - b;
    });

  const fixHora = (hora: string) =>
    (hora || "").replace(/^(\d{2}):(\d{2}):\d{2}/gi, "$1:$2");

  return filtroHorarios(ordenados, props.limite, hora.value, dia.value).map((h) =>
    Object.assign({}, h, {
      rel: separarHora(h.S),
      S: fixHora(h.S),
      T: fixHora(h.T),
      V: h.V?.join(", "),
      ...(h.S2 && { S2: fixHora(h.S2) }),
      ...(h.T2 && { S2: fixHora(h.T2) }),
    })
  );
});
const horariosVuelta = computed(() => props.horariosVuelta || new Map());
</script>
