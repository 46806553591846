<template>
  <div class="mb-estacion__select-container">
    <h2 class="text-xs uppercase tracking-wider leading-none mb-4">Estación {{ tipo }}</h2>

    <button type="button" class="flex py-4 text-left border-b border-grey-light bg-transparent  text-2xl leading-none items-center dark:border-gray-700 dark:text-gray-200" @click="state.show = !state.show" :data-testid="`cambiar-estacion-${tipo.toLowerCase()}`">
      <span v-if="!estacion?.nom" class="block h-[24px] w-[100px] rounded-sm" style="background-color: hsla(0, 0%, 100%, 0.2)"></span>
      <span v-else class="mr-4 text-2xl leading-none">{{ (estacion || {}).nom }}</span>
      <Icon
        name="mdi:chevron-down"
        width="24"
        height="24"
        :class="[state.show && 'rotate-180', 'stroke-current transition-all text-orange']"
      />
    </button>

    <ul class="h-[200px] bg-white overflow-y-auto border border-grey-lighter shadow absolute z-10 w-full dark:bg-gray-900 dark:border-gray-700" v-show="state.show">
      <li v-for="ests in estaciones" :key="ests.id" class="flex items-center border-b border-grey-lighter dark:border-gray-700">
        <button type="button" aria-label="Pointer" aria-hidden="true" class="p-8 text-left">
          <Icon
            name="ri:map-pin-2-line"
            width="16"
            height="16"
            aria-hidden="true"
            :style="`color: ${ests.color}`"
            fill
          />
        </button>
        <button type="button" class="flex-1 p-8 text-left" @click="selectEstacion(ests)" :data-testid="`select-${tipo.toLowerCase()}-${ests.id}`">
          {{ ests.nom }}
        </button>
        <button type="button" @click="starEstacion(ests)" aria-label="Destacar Estación" class="p-8 text-left">
          <Icon
            :name="
              ests.destacado ? 'material-symbols:star' : 'material-symbols:star-outline'
            "
            width="16"
            height="16"
            aria-hidden="true"
            :style="`color: ${ests.destacado ? '#EA9215' : 'white'};`"
          />
        </button>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import type { IEstacion } from "@/interfaces";
import { zonas } from "@/config";

interface Props {
  tipo: String;
  estacionId: String;
  estacionesBase: IEstacion[];
  estacionesUser: string[];
}

const props: Props = defineProps({
  tipo: { type: String, default: "Salida" },
  estacionId: { type: String, default: "1" },
  estacionesBase: { default: () => [] as IEstacion[] },
  estacionesUser: { default: () => [] as string[] },
});
const estacionBase = computed(() =>
  props.estacionesBase.find((est) => String(est.id) === String(props.estacionId))
);

const estacion = ref(estacionBase.value || props.estacionesBase[0]);

const state = reactive({
  show: false,
});

const estaciones = computed<IEstaciones[]>(() => {
  const estacionesDestacadas = props.estacionesBase
    .filter((est) => props.estacionesUser.includes(String(est.id)))
    .map((est) => Object.assign({}, est, { destacado: true }));

  const estacionesNormales = props.estacionesBase.filter(
    (est) => !props.estacionesUser.includes(String(est.id))
  );

  const newestaciones = [...estacionesDestacadas, ...estacionesNormales].map((est) =>
    Object.assign({}, est, { color: zonas[0] })
  );

  return newestaciones;
});

watch(
  () => props.estacionId,
  () => {
    const est = estaciones.value.find(
      (est) => String(est.id) === String(props.estacionId)
    );
    estacion.value = est || estacion.value;
  },
  { deep: true, immediate: true }
);

type IEstaciones = IEstacion & { destacado?: boolean; color?: string };

const emit = defineEmits(["estacion", "star"]);

const starEstacion = (est: IEstacion) => emit("star", est);

const selectEstacion = (est: IEstacion) => {
  estacion.value = est;
  state.show = false;

  emit("estacion", estacion.value);
};
</script>

<style lang="scss" scoped>
// .mb-estacion__select-container {
//   @apply relative;

//   h2 {
//     @apply text-xs uppercase tracking-wider leading-none mb-4;
//   }

//   > button {
//     @apply flex py-4 text-left border-b border-grey-light bg-transparent  text-2xl leading-none items-center;

//     span {
//       @apply mr-4 text-2xl leading-none;
//     }
//     svg {
//       transition: all 0.2s ease-in-out;
//       @apply text-orange;

//       &.rotate {
//         transform: rotate(180deg);
//       }
//     }
//   }

//   ul {
//     height: 200px;
//     @apply bg-white overflow-y-auto border border-grey-lighter shadow absolute z-10 w-full;

//     li {
//       @apply flex items-center border-b border-grey-lighter;

//       input {
//         @apply text-xl py-4 px-4 w-full;
//       }

//       > button {
//         @apply p-8 text-left;
//       }
//     }
//   }
// }
// .dark-mode {
//   .mb-estacion__select-container {
//     > button {
//       @apply border-gray-700 text-gray-200;
//     }

//     ul {
//       @apply bg-gray-900 border-gray-700;

//       li {
//         @apply border-gray-700;

//         input {
//           @apply bg-gray-900;
//         }
//       }
//     }
//   }
// }
</style>
